import React from 'react';
import Helmet from 'react-helmet';

import {Button, ButtonTypes} from 'benlux-ecommerce-ui';

import ChanelShowcase from './vitrine-chanel.jpg';
import ChanelCC from './chanel-cc.png';
import './index.css';

const ChanelCallAndDeliver = () =>
  <div style={{borderTop: '8px solid #000', maxWidth: '1300px', margin: '0 auto', textAlign: 'center'}}>
    <Helmet>
      <title>Chanel</title>
    </Helmet>
    <h1 style={{width: '180px', margin: '24px auto'}}>
      <svg className="logo" focusable="false" aria-hidden="true"
           viewBox="0 0 665.039 103.376" width="100%" height="100%" aria-label="Chanel">
        <path
          d="M78.792 66.996L96.21 76.97c-8.707 16.012-25.704 26.406-44.945 26.406C23.175 103.376 0 81.886 0 51.688 0 21.49 23.175 0 51.264 0c19.242 0 36.238 10.394 44.944 26.405L78.79 36.38c-5.055-10.395-15.308-16.715-27.527-16.715-17.558 0-30.9 13.06-30.9 32.022 0 18.962 13.342 32.022 30.9 32.022 12.22 0 22.473-6.32 27.53-16.714zM189.61 100.848V58.71h-49.16v42.138h-19.662V2.528h19.662v36.517h49.16V2.528h19.662v98.32H189.61zM342.704 100.848h-21.49l-9.692-22.476h-51.406l-9.69 22.476h-21.492l43.543-98.32h26.688l43.54 98.32zM303.66 60.115L285.82 18.68 267.98 60.115h35.677zM433.294 100.848l-52.673-77.39v77.39h-18.256V2.528h25.986l44.24 65.17V2.53h18.262v98.32h-17.558zM488.77 100.848V2.528H559v18.26H507.03v21.067h44.946v18.26H507.03v22.473h54.778v18.26H488.77zM593.41 100.848V2.528h19.663v78.655h51.966v19.665h-71.63z"></path>
      </svg>
    </h1>
    <img src={ChanelShowcase} alt={'Vitrine Chanel'} width={'100%'}/>
    <p className="chanel-intro" style={{fontFamily: '"ABChanelCorpo", sans-serif', textTransform: 'uppercase', fontWeight: 'bold', fontSize: '28px', margin: '24px auto 48px', lineHeight: '150%', maxWidth: '900px'}}>Les produits Chanel Parfums Beauté<br/> sont disponibles dans notre parfumerie :</p>
    <p>
      <span style={{textTransform: 'uppercase'}}><b>Parfumerie BENLUX</b></span><br/>
      <span>174 rue de Rivoli</span><br/>
      <span style={{textTransform: 'uppercase'}}>75001 Paris</span><br/>
      <span><b>01 47 03 66 66</b></span>
    </p>
    <div style={{marginTop: '24px'}}>
      <Button
        text={'Découvrir le magasin'}
        type={ButtonTypes.Tertiary}
        as={'link'}
        href={'/magasin-paris'}
      />
    </div>
    <img src={ChanelCC} alt={'Chanel CC'} width={'72px'} style={{marginTop: '96px'}}/>
  </div>

export default ChanelCallAndDeliver;
